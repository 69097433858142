import "core-js/modules/es6.regexp.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.regexp.split";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Head from '@/components/head/index';
import { BrochuresList, Brochuresone, Brochuresdel, BrochuresAdd, Brochuresupdate, Brochuresup } from '@/api/ceshi_xiugai/product';
import area from '@/views/example/area.js';
import { schoolList } from '@/api/PublicAPI';
export default {
  data: function data() {
    return {
      // 系统教程
      tab: 0,
      course: this.$route.query.course,
      prohibit: false,
      // 系统教程
      tempId: '',
      area: area,
      title: '简章管理',
      Alltableoptions: [],
      query: {
        page: 1,
        pagesize: 10,
        total: 0 //总条数，不是总页数
      },
      key_word: '',
      tableData: [],
      displayValue: [],
      tableKey: 0,
      InformationBox: false,
      //详情弹窗
      ruleForm: {},
      region: '',
      major: '',
      editForm: {
        content: '',
        //简介
        title: '',
        //简章标题
        school_id: '',
        //院校选择
        major_id: '',
        //专业
        eductional_systme: '',
        //学制
        district: '',
        //地区
        province: '',
        //省
        city: '',
        //城市
        country: '',
        //县
        address: '',
        //详情地址
        tuition: '',
        //学费
        entry_fee: '',
        //报名费
        information_fee: '',
        //资料费
        materials_fee: '',
        //材料费
        filing_fee: '',
        //申请费
        total_price: '',
        //总价
        project_type: '',
        //项目分类
        teaching_type: '',
        //授课方式
        recommen: '',
        //推介指数
        education: '',
        //报考项目
        test_type: '',
        //考试形式
        file_id: '' //附件id
      },
      row: undefined,
      textMap: {
        update: '编辑',
        create: '新增'
      },
      dialogStatus: '',
      dialogFormVisible: false,
      rules: {
        title: [{
          required: true,
          message: '请输入简章名称',
          trigger: 'blur'
        }],
        school_id: [{
          required: true,
          message: '请选择',
          trigger: 'blur'
        }],
        content: [{
          required: true,
          message: '不能为空',
          trigger: 'blur'
        }],
        institutions: [{
          required: true,
          message: '不能为空',
          trigger: 'blur'
        }],
        eductional_systme: [{
          required: true,
          message: '不能为空',
          trigger: 'blur'
        }],
        major_title: [{
          required: true,
          message: '不能为空',
          trigger: 'blur'
        }],
        project: [{
          required: true,
          message: '请至少选择一个',
          trigger: 'blur'
        }],
        teaching_type: [{
          required: true,
          message: '请选择',
          trigger: 'blur'
        }],
        recommen: [{
          required: true,
          message: '请选择',
          trigger: 'blur'
        }],
        education: [{
          required: true,
          message: '请选择',
          trigger: 'blur'
        }],
        test_type: [{
          required: true,
          message: '请选择',
          trigger: 'blur'
        }],
        tuition: [{
          required: true,
          message: '请输入学费',
          trigger: 'blur'
        }],
        region: [{
          required: true,
          message: '请输入',
          trigger: 'blur'
        }]
      },
      direct: [],
      directionproject: [],
      school_list: [],
      //学校
      brochures_eductional_systme: [],
      //学制
      major_list: [],
      project: [],
      brochures_project: [],
      //项目分类
      brochures_teaching_type: [],
      //授课方式
      brochures_recommen: [],
      //推荐指数
      brochures_education: [],
      //报考项目
      brochures_test_type: [],
      //考试形式
      editor: null,
      colleges: 1,
      //院校分页,
      chatmodes: [],
      //意向院校
      institutions: '' //意向院校val值
    };
  },
  components: {
    Head: Head
  },
  mounted: function mounted() {
    if (this.course != 1) {
      this.GetList();
    } else {}
  },
  methods: {
    loadMore: function loadMore() {
      var _this = this;
      // 意向院校分页，下拉增加数据
      this.colleges++;
      schoolList({
        page: String(this.colleges),
        pagesize: '10'
      }).then(function (res) {
        for (var key in res.data.school_list) {
          _this.chatmodes.push(res.data.school_list[key]);
        }
      });
    },
    remoteMethod: function remoteMethod(val) {
      //获取意向院校所选的值
      this.editForm.school_id = String(val);
    },
    zjChange: function zjChange() {
      this.editForm.total_price = (parseInt(this.editForm.tuition > 0 ? this.editForm.tuition : 0) + parseInt(this.editForm.entry_fee > 0 ? this.editForm.entry_fee : 0) + parseInt(this.editForm.information_fee > 0 ? this.editForm.information_fee : 0) + parseInt(this.editForm.materials_fee > 0 ? this.editForm.materials_fee : 0) + parseInt(this.editForm.filing_fee > 0 ? this.editForm.filing_fee : 0)).toFixed(2);
    },
    //上传
    uploadSectionFile: function uploadSectionFile(param) {
      var _this2 = this;
      var newfile = new FormData();
      newfile.append('file', param.file);
      newfile.append('type', '200008');
      Brochuresup(newfile).then(function (res) {
        _this2.editForm.file_id = res.data.id;
      });
    },
    //选中
    selsChange: function selsChange(val) {
      this.Alltableoptions = val;
      this.editForm.content = val.content;
      this.editForm = Object.assign({}, this.Alltableoptions[0]);
      for (var i = 0; i < val.length; i++) {
        this.tempId = val[i].id;
      }
      if (val.length > 1) {
        this.$refs.tb.clearSelection();
        this.$refs.tb.toggleRowSelection(val.pop());
      }
    },
    GetList: function GetList() {
      var _this3 = this;
      BrochuresList({
        pagesize: this.query.pagesize,
        page: this.query.page,
        key_word: this.key_word
      }).then(function (respomse) {
        _this3.tableData = respomse.data.brochures_list;
        _this3.query.total = respomse.data.num;
        _this3.displayValue = respomse.data.brochures_fields;
        _this3.brochures_eductional_systme = respomse.data.brochures_eductional_systme; //学制
        _this3.major_list = respomse.data.major_list; //专业
        _this3.brochures_project = respomse.data.brochures_project; //项目分类
        _this3.brochures_teaching_type = respomse.data.brochures_teaching_type; //授课方式
        _this3.brochures_recommen = respomse.data.brochures_recommen;
        _this3.brochures_education = respomse.data.brochures_education; //报考项目
        _this3.brochures_test_type = respomse.data.brochures_test_type; //考试形式
      });
      schoolList({
        page: this.colleges,
        pagesize: '15'
      }).then(function (res) {
        _this3.chatmodes = res.data.school_list;
      });
    },
    // 点击查看详情
    SeeDetailsBtn: function SeeDetailsBtn(row) {
      var _this4 = this;
      this.row = row;
      Brochuresone({
        id: row.id
      }).then(function (respomse) {
        _this4.ruleForm = Object.assign({}, respomse.data.brochures_info);
        _this4.editForm = Object.assign({}, respomse.data.brochures_info);
      });
      this.ruleForm = Object.assign({}, this.row);
      this.editForm = Object.assign({}, this.row);
      this.InformationBox = true;
    },
    // 详情编辑
    CollegeInformationEditor: function CollegeInformationEditor() {
      this.InformationBox = false;
      this.dialogFormVisible = true;
      this.institutions = this.row.schoole_title;
      this.major = this.row.major_title;
      this.region = this.row.province;
      this.project = this.row.project_type.split(',');
    },
    // 详情删除按钮
    InstitutionInformationDeletion: function InstitutionInformationDeletion() {
      var _this5 = this;
      this.$confirm('确定删除吗?', '提示', {
        type: 'warning'
      }).then(function () {
        Brochuresdel({
          id: _this5.row.id
        }).then(function (res) {
          _this5.$notify({
            message: '删除成功',
            type: 'success'
          });
          _this5.$nextTick(function () {
            //获取列表方法
            _this5.GetList();
          });
          _this5.InformationBox = false;
        });
      });
    },
    //新建
    handleAddgen: function handleAddgen() {
      var _this6 = this;
      this.dialogStatus = 'create';
      this.dialogFormVisible = true;
      this.project = [];
      this.institutions = '';
      this.major = '';
      Object.keys(this.editForm).forEach(function (key) {
        return _this6.editForm[key] = '';
      });
    },
    createData: function createData() {
      var _this7 = this;
      this.$refs['editForm'].validate(function (valid) {
        if (valid) {
          _this7.editForm.major_id = _this7.major;
          _this7.editForm.project_type = _this7.project.toString();
          _this7.editForm.province = _this7.editForm.district[0];
          _this7.editForm.city = _this7.editForm.district[1];
          _this7.editForm.country = _this7.editForm.district[2];
          BrochuresAdd(_this7.editForm).then(function (res) {
            _this7.$notify({
              message: '添加成功',
              type: 'success'
            });
            _this7.dialogFormVisible = false;
            Object.keys(_this7.editForm).forEach(function (key) {
              return _this7.editForm[key] = '';
            });
            _this7.$nextTick(function () {
              //获取列表方法
              _this7.GetList();
            });
          });
        } else {
          return false;
        }
      });
    },
    //编辑
    handleupdatagen: function handleupdatagen() {
      var _this8 = this;
      if (this.Alltableoptions.length !== 0) {
        if (this.Alltableoptions.length == 1) {
          this.dialogStatus = 'update';
          this.dialogFormVisible = true;
          Brochuresone({
            id: this.tempId
          }).then(function (respomse) {
            _this8.ruleForm = Object.assign({}, respomse.data.brochures_info);
            _this8.editForm = Object.assign({}, respomse.data.brochures_info);
            _this8.editForm.district = respomse.data.brochures_info.area.split(',');
            _this8.institutions = respomse.data.brochures_info.school_title;
            _this8.major = respomse.data.brochures_info.major_title;
            _this8.project = respomse.data.brochures_info.project_type.split(',');
          });

          // this.editForm.recommen = Number(this.editForm.recommen);
        } else {
          this.$message('只能选择一个进行编辑');
        }
      } else {
        this.$message({
          type: 'info',
          message: '请选择一个编辑'
        });
      }
    },
    updateData: function updateData() {
      var _this9 = this;
      this.editForm.project_type = this.project.toString();
      this.editForm.province = this.editForm.district[0];
      this.editForm.city = this.editForm.district[1];
      this.editForm.country = this.editForm.district[2];
      Brochuresupdate(this.editForm).then(function () {
        _this9.$message({
          message: '提交成功',
          type: 'success'
        });
        _this9.$nextTick(function () {
          _this9.GetList();
        });
        _this9.dialogFormVisible = false;
      });
    },
    //删除
    handledelgen: function handledelgen() {
      var _this10 = this;
      if (this.Alltableoptions.length !== 0) {
        this.$confirm('确定删除吗?', '提示', {
          type: 'warning'
        }).then(function () {
          var para = {
            id: _this10.tempId.toString()
          };
          Brochuresdel(para).then(function (res) {
            _this10.$notify({
              message: '删除成功',
              type: 'success'
            });
            _this10.$nextTick(function () {
              //获取列表方法
              _this10.GetList();
            });
          });
        }).catch(function () {});
      } else {
        this.$message('请选中删除项');
      }
    },
    //分页
    handleCurrentChange: function handleCurrentChange(val) {
      var _this11 = this;
      this.query.page = val;
      this.$nextTick(function () {
        _this11.GetList();
      });
    },
    handleSizeChange: function handleSizeChange(val) {
      var _this12 = this;
      this.query.pagesize = val;
      this.$nextTick(function () {
        _this12.GetList();
      });
    },
    //查询
    handleFilter: function handleFilter() {
      var _this13 = this;
      this.$nextTick(function () {
        _this13.query.page = 1;
        _this13.GetList();
      });
    }
  }
};