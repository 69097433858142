var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("el-container", { staticClass: "app-container" }, [
    _vm.course != 1
      ? _c(
          "div",
          { staticStyle: { width: "100%" } },
          [
            _c(
              "el-header",
              { staticStyle: { height: "80px" } },
              [
                _c(
                  "el-row",
                  {
                    staticStyle: { height: "30px", margin: "10px 0px" },
                    attrs: { gutter: 24 },
                  },
                  [_c("Head", { attrs: { name: _vm.title } })],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-row",
                  { attrs: { gutter: 24 } },
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 18 } },
                      [
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "permission",
                                rawName: "v-permission",
                                value: ["新建"],
                                expression: "['新建']",
                              },
                            ],
                            attrs: { type: "primary", size: "small" },
                            on: { click: _vm.handleAddgen },
                          },
                          [_vm._v("新建简章")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "permission",
                                rawName: "v-permission",
                                value: ["编辑"],
                                expression: "['编辑']",
                              },
                            ],
                            staticClass: "m0",
                            attrs: { type: "primary", size: "small" },
                            on: { click: _vm.handleupdatagen },
                          },
                          [_vm._v("编辑")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "permission",
                                rawName: "v-permission",
                                value: ["删除"],
                                expression: "['删除']",
                              },
                            ],
                            staticClass: "m0",
                            attrs: { type: "primary", size: "small" },
                            on: { click: _vm.handledelgen },
                          },
                          [_vm._v("删除")]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { staticStyle: { float: "right" }, attrs: { span: 5 } },
                      [
                        _c(
                          "el-input",
                          {
                            staticClass: "input-with-select",
                            attrs: {
                              placeholder: "请输入简章标题查询",
                              size: "small",
                              clearable: "",
                            },
                            nativeOn: {
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                                return _vm.handleFilter($event)
                              },
                            },
                            model: {
                              value: _vm.key_word,
                              callback: function ($$v) {
                                _vm.key_word = $$v
                              },
                              expression: "key_word",
                            },
                          },
                          [
                            _c("el-button", {
                              attrs: {
                                slot: "append",
                                type: "primary",
                                size: "small",
                                icon: "el-icon-search",
                              },
                              on: { click: _vm.handleFilter },
                              slot: "append",
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-main",
              [
                _c(
                  "el-table",
                  {
                    ref: "tb",
                    staticStyle: { width: "100%" },
                    attrs: {
                      data: _vm.tableData,
                      "tooltip-effect": "dark",
                      border: "",
                      "header-cell-style": {
                        background: "#F9F9F9",
                        color: "#222222",
                      },
                    },
                    on: { "selection-change": _vm.selsChange },
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        type: "selection",
                        align: "center",
                        width: "50",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { label: "序号", align: "center", width: "50" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      scope.$index +
                                        (_vm.query.page - 1) *
                                          _vm.query.pagesize +
                                        1
                                    ) + " "
                                  ),
                                ]),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        3093164208
                      ),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "简章标题",
                        width: "200",
                        align: "center",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return _c(
                                "span",
                                {
                                  staticClass: "blue-tit",
                                  on: {
                                    click: function ($event) {
                                      return _vm.SeeDetailsBtn(scope.row)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                        " +
                                      _vm._s(scope.row.title) +
                                      "\n                    "
                                  ),
                                ]
                              )
                            },
                          },
                        ],
                        null,
                        false,
                        1133963704
                      ),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "schoole_title",
                        label: "院校选择",
                        width: "180",
                        align: "center",
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "el-table-column",
                      {
                        attrs: {
                          prop: "major_title",
                          label: "专业",
                          align: "center",
                          width: "180",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _vm._v(
                                    "\n                        " +
                                      _vm._s(scope.row.eductional_systme) +
                                      "年\n                    "
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2256250293
                        ),
                      },
                      [_vm._v("\n                    >")]
                    ),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "district",
                        label: "地区",
                        align: "center",
                        width: "100",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "tuition",
                        label: "学费",
                        align: "center",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "entry_fee",
                        label: "报名费",
                        align: "center",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "information_fee",
                        label: "资料费",
                        align: "center",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "materials_fee",
                        label: "材料费",
                        align: "center",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "filing_fee",
                        label: "申请费",
                        align: "center",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "total_price",
                        label: "总价",
                        align: "center",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "project_type",
                        label: "项目分类",
                        align: "center",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "teaching_type",
                        label: "授课方式",
                        align: "center",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "recommen",
                        label: "推荐指数",
                        align: "center",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(scope.row.recommen) +
                                    "星\n                    "
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        3798276850
                      ),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "education",
                        label: "报考项目",
                        align: "center",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "test_type",
                        label: "考试形式",
                        align: "center",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("pagination", {
                  attrs: {
                    "current-page": _vm.query.page,
                    totalPage: _vm.query.total,
                    pageSize: _vm.query.pagesize,
                  },
                  on: {
                    handleSizeChange: _vm.handleSizeChange,
                    handleCurrentChange: _vm.handleCurrentChange,
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-dialog",
              {
                attrs: {
                  width: "80%",
                  title: _vm.textMap[_vm.dialogStatus],
                  visible: _vm.dialogFormVisible,
                },
                on: {
                  "update:visible": function ($event) {
                    _vm.dialogFormVisible = $event
                  },
                },
              },
              [
                _c(
                  "el-form",
                  {
                    ref: "editForm",
                    attrs: { "label-width": "100px", model: _vm.editForm },
                  },
                  [
                    _c(
                      "el-form-item",
                      {
                        staticClass: "is-required",
                        attrs: { label: "简介名称", prop: "title" },
                      },
                      [
                        _c("el-input", {
                          staticStyle: { width: "280px" },
                          attrs: { placeholder: "请输入简介名称" },
                          model: {
                            value: _vm.editForm.title,
                            callback: function ($$v) {
                              _vm.$set(_vm.editForm, "title", $$v)
                            },
                            expression: "editForm.title",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      {
                        staticClass: "is-required",
                        attrs: { label: "简介", prop: "content" },
                      },
                      [
                        _c("quill-editor", {
                          ref: "myTextEditor",
                          staticClass: "ql-container",
                          attrs: { options: _vm.editorOption },
                          model: {
                            value: _vm.editForm.content,
                            callback: function ($$v) {
                              _vm.$set(_vm.editForm, "content", $$v)
                            },
                            expression: "editForm.content",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "lists_box" }, [
                      _c("ul", [
                        _c(
                          "li",
                          [
                            _c(
                              "el-form-item",
                              {
                                staticClass: "is-required",
                                attrs: {
                                  label: "院校选择",
                                  prop: "school_title",
                                },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    directives: [
                                      {
                                        name: "loadmore",
                                        rawName: "v-loadmore",
                                        value: _vm.loadMore,
                                        expression: "loadMore",
                                      },
                                    ],
                                    staticClass: "colleges",
                                    attrs: {
                                      filterable: "",
                                      remote: "",
                                      "reserve-keyword": "",
                                      placeholder: "请输入关键词",
                                    },
                                    on: { change: _vm.remoteMethod },
                                    model: {
                                      value: _vm.institutions,
                                      callback: function ($$v) {
                                        _vm.institutions = $$v
                                      },
                                      expression: "institutions",
                                    },
                                  },
                                  _vm._l(_vm.chatmodes, function (item) {
                                    return _c("el-option", {
                                      key: item.id,
                                      attrs: {
                                        label: item.title,
                                        value: item.id,
                                      },
                                    })
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              {
                                staticClass: "is-required",
                                attrs: {
                                  label: "学制:",
                                  prop: "eductional_systme",
                                },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: {
                                      clearable: "",
                                      placeholder: "请选择学制",
                                    },
                                    model: {
                                      value: _vm.editForm.eductional_systme,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.editForm,
                                          "eductional_systme",
                                          $$v
                                        )
                                      },
                                      expression: "editForm.eductional_systme",
                                    },
                                  },
                                  _vm._l(
                                    _vm.brochures_eductional_systme,
                                    function (v, i) {
                                      return _c("el-option", {
                                        key: i,
                                        staticStyle: { padding: "0 20px" },
                                        attrs: { label: v, value: v },
                                      })
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "li",
                          [
                            _c(
                              "el-form-item",
                              {
                                staticClass: "is-required",
                                staticStyle: { "margin-bottom": "20px" },
                                attrs: { label: "专业:", prop: "major_title" },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: { placeholder: "请选择" },
                                    model: {
                                      value: _vm.major,
                                      callback: function ($$v) {
                                        _vm.major = $$v
                                      },
                                      expression: "major",
                                    },
                                  },
                                  _vm._l(_vm.major_list, function (v, i) {
                                    return _c("el-option", {
                                      key: i,
                                      staticStyle: { padding: "0 20px" },
                                      attrs: { label: v, value: i },
                                    })
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "li",
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "可取得学位:" } },
                              [
                                _c("el-input", {
                                  model: {
                                    value: _vm.editForm.get_degree,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.editForm, "get_degree", $$v)
                                    },
                                    expression: "editForm.get_degree",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              { attrs: { label: "报名条件:" } },
                              [
                                _c("el-input", {
                                  model: {
                                    value: _vm.editForm.conditions,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.editForm, "conditions", $$v)
                                    },
                                    expression: "editForm.conditions",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      [
                        _c(
                          "el-form-item",
                          {
                            staticClass: "is-required",
                            attrs: { label: "项目分类", prop: "project" },
                          },
                          [
                            _c(
                              "el-checkbox-group",
                              {
                                model: {
                                  value: _vm.project,
                                  callback: function ($$v) {
                                    _vm.project = $$v
                                  },
                                  expression: "project",
                                },
                              },
                              _vm._l(_vm.brochures_project, function (item) {
                                return _c(
                                  "el-checkbox",
                                  {
                                    key: item,
                                    attrs: { label: item, value: item },
                                  },
                                  [_vm._v(_vm._s(item))]
                                )
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          {
                            staticClass: "is-required",
                            attrs: { label: "授课方式", prop: "teaching_type" },
                          },
                          _vm._l(_vm.brochures_teaching_type, function (item) {
                            return _c(
                              "el-radio",
                              {
                                key: item,
                                attrs: { label: item, value: item },
                                model: {
                                  value: _vm.editForm.teaching_type,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.editForm, "teaching_type", $$v)
                                  },
                                  expression: "editForm.teaching_type",
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(item) + "\n                        "
                                ),
                              ]
                            )
                          }),
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          {
                            staticClass: "is-required",
                            attrs: { label: "推荐指数", prop: "recommen" },
                          },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: {
                                  clearable: "",
                                  placeholder: "请选择推荐指数",
                                },
                                model: {
                                  value: _vm.editForm.recommen,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.editForm, "recommen", $$v)
                                  },
                                  expression: "editForm.recommen",
                                },
                              },
                              _vm._l(_vm.brochures_recommen, function (v, i) {
                                return _c("el-option", {
                                  key: i,
                                  staticStyle: { padding: "0 20px" },
                                  attrs: { label: v, value: v },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          {
                            staticClass: "is-required",
                            staticStyle: { "margin-bottom": "20px" },
                            attrs: { label: "报考项目:", prop: "education" },
                          },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: { placeholder: "请选择" },
                                model: {
                                  value: _vm.editForm.education,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.editForm, "education", $$v)
                                  },
                                  expression: "editForm.education",
                                },
                              },
                              _vm._l(_vm.brochures_education, function (v, i) {
                                return _c("el-option", {
                                  key: i,
                                  staticStyle: { padding: "0 20px" },
                                  attrs: { label: v, value: v },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          {
                            staticClass: "is-required",
                            staticStyle: { "margin-bottom": "20px" },
                            attrs: { label: "考试形式:", prop: "test_type" },
                          },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: { placeholder: "请选择" },
                                model: {
                                  value: _vm.editForm.test_type,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.editForm, "test_type", $$v)
                                  },
                                  expression: "editForm.test_type",
                                },
                              },
                              _vm._l(_vm.brochures_test_type, function (v, i) {
                                return _c("el-option", {
                                  key: i,
                                  staticStyle: { padding: "0 20px" },
                                  attrs: { label: v, value: v },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      [
                        _c(
                          "el-form-item",
                          {
                            staticClass: "w20 is-required",
                            attrs: { label: "学费:", prop: "tuition" },
                          },
                          [
                            _c("el-input", {
                              attrs: { placeholder: "(元)" },
                              on: { change: _vm.zjChange },
                              model: {
                                value: _vm.editForm.tuition,
                                callback: function ($$v) {
                                  _vm.$set(_vm.editForm, "tuition", $$v)
                                },
                                expression: "editForm.tuition",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          { staticClass: "w20", attrs: { label: "报名费:" } },
                          [
                            _c("el-input", {
                              attrs: { placeholder: "(元)" },
                              on: { change: _vm.zjChange },
                              model: {
                                value: _vm.editForm.entry_fee,
                                callback: function ($$v) {
                                  _vm.$set(_vm.editForm, "entry_fee", $$v)
                                },
                                expression: "editForm.entry_fee",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          { staticClass: "w20", attrs: { label: "资料费:" } },
                          [
                            _c("el-input", {
                              attrs: { placeholder: "(元)" },
                              on: { change: _vm.zjChange },
                              model: {
                                value: _vm.editForm.information_fee,
                                callback: function ($$v) {
                                  _vm.$set(_vm.editForm, "information_fee", $$v)
                                },
                                expression: "editForm.information_fee",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          { staticClass: "w20", attrs: { label: "材料费:" } },
                          [
                            _c("el-input", {
                              attrs: { placeholder: "(元)" },
                              on: { change: _vm.zjChange },
                              model: {
                                value: _vm.editForm.materials_fee,
                                callback: function ($$v) {
                                  _vm.$set(_vm.editForm, "materials_fee", $$v)
                                },
                                expression: "editForm.materials_fee",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          { staticClass: "w20", attrs: { label: "申请费:" } },
                          [
                            _c("el-input", {
                              attrs: { placeholder: "(元)" },
                              on: { change: _vm.zjChange },
                              model: {
                                value: _vm.editForm.filing_fee,
                                callback: function ($$v) {
                                  _vm.$set(_vm.editForm, "filing_fee", $$v)
                                },
                                expression: "editForm.filing_fee",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("el-form-item", { attrs: { label: "总价:" } }, [
                          _c("span", [
                            _vm._v(_vm._s(_vm.editForm.total_price) + "(元)"),
                          ]),
                        ]),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "RequiredInformation" },
                      [
                        _c(
                          "el-row",
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 12 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    staticClass: "is-required",
                                    staticStyle: { width: "100%" },
                                    attrs: { label: "省市区:", prop: "region" },
                                  },
                                  [
                                    _c("el-cascader", {
                                      ref: "cascader",
                                      staticClass: "pct50",
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        value: _vm.editForm.district,
                                        placeholder: "试试搜索：美国",
                                        options: _vm.area,
                                        "expand-trigger": "hover",
                                        props: {
                                          checkStrictly: true,
                                          value: "label",
                                        },
                                        filterable: "",
                                      },
                                      model: {
                                        value: _vm.editForm.district,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.editForm,
                                            "district",
                                            $$v
                                          )
                                        },
                                        expression: "editForm.district",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-col",
                              { attrs: { span: 12 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    staticStyle: { width: "100%" },
                                    attrs: { label: "详细地址:" },
                                  },
                                  [
                                    _c("el-input", {
                                      staticStyle: { width: "100%" },
                                      attrs: { placeholder: "请输入详细地址" },
                                      model: {
                                        value: _vm.editForm.address,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.editForm, "address", $$v)
                                        },
                                        expression: "editForm.address",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "摘要", prop: "remark" } },
                      [
                        _c("el-input", {
                          attrs: {
                            type: "textarea",
                            placeholder: "请输入内容",
                          },
                          model: {
                            value: _vm.editForm.remark,
                            callback: function ($$v) {
                              _vm.$set(_vm.editForm, "remark", $$v)
                            },
                            expression: "editForm.remark",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "附件", prop: "file_id" } },
                      [
                        _c(
                          "el-upload",
                          {
                            staticClass: "avatar-uploader",
                            attrs: {
                              "http-request": _vm.uploadSectionFile,
                              "show-file-list": true,
                              action: "action",
                            },
                          },
                          [
                            _c(
                              "el-button",
                              { attrs: { size: "small", type: "primary" } },
                              [_vm._v("点击上传")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "dialog-footer",
                    attrs: { slot: "footer" },
                    slot: "footer",
                  },
                  [
                    _c(
                      "el-button",
                      {
                        on: {
                          click: function ($event) {
                            _vm.dialogFormVisible = false
                          },
                        },
                      },
                      [_vm._v("\n                    取消\n                ")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: {
                          click: function ($event) {
                            _vm.dialogStatus === "create"
                              ? _vm.createData()
                              : _vm.updateData()
                          },
                        },
                      },
                      [_vm._v("\n                    确认\n                ")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-dialog",
              {
                staticClass: "big-dialog",
                attrs: { title: "简章详情", visible: _vm.InformationBox },
                on: {
                  "update:visible": function ($event) {
                    _vm.InformationBox = $event
                  },
                },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "header-title",
                    attrs: { slot: "title" },
                    slot: "title",
                  },
                  [
                    _c("span", { staticClass: "title-name" }, [
                      _vm._v("简章详情"),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "headerBtn" },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.CollegeInformationEditor()
                              },
                            },
                          },
                          [_vm._v("编辑")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.InstitutionInformationDeletion()
                              },
                            },
                          },
                          [_vm._v("删除")]
                        ),
                      ],
                      1
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("div", [
                  _c(
                    "table",
                    {
                      staticClass: "tabstyles",
                      attrs: { border: "1", rules: "all" },
                    },
                    [
                      _c("tr", [
                        _c("th", [_vm._v("简章标题:")]),
                        _vm._v(" "),
                        _c("td", { staticClass: "blueFont" }, [
                          _vm._v(_vm._s(_vm.ruleForm.title)),
                        ]),
                        _vm._v(" "),
                        _c("th", [_vm._v("地区:")]),
                        _vm._v(" "),
                        _c("td", [_vm._v(_vm._s(_vm.ruleForm.province))]),
                      ]),
                      _vm._v(" "),
                      _c("tr", [
                        _c("th", [_vm._v("院校选择:")]),
                        _vm._v(" "),
                        _c("td", [_vm._v(_vm._s(_vm.ruleForm.school_title))]),
                        _vm._v(" "),
                        _c("th", [_vm._v("学制:")]),
                        _vm._v(" "),
                        _c("td", [
                          _vm._v(_vm._s(_vm.ruleForm.eductional_systme)),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("tr", [
                        _c("th", [_vm._v("专业:")]),
                        _vm._v(" "),
                        _c("td", [_vm._v(_vm._s(_vm.ruleForm.major_title))]),
                        _vm._v(" "),
                        _c("th", [_vm._v("授课形式:")]),
                        _vm._v(" "),
                        _c("td", [_vm._v(_vm._s(_vm.ruleForm.teaching_type))]),
                      ]),
                      _vm._v(" "),
                      _c("tr", [
                        _c("th", [_vm._v("推荐指数:")]),
                        _vm._v(" "),
                        _c("td", [_vm._v(_vm._s(_vm.ruleForm.recommen))]),
                      ]),
                      _vm._v(" "),
                      _c("tr", [
                        _c("th", [_vm._v("学历:")]),
                        _vm._v(" "),
                        _c("td", [_vm._v(_vm._s(_vm.ruleForm.education))]),
                        _vm._v(" "),
                        _c("th", [_vm._v("项目分类:")]),
                        _vm._v(" "),
                        _c("td", [_vm._v(_vm._s(_vm.ruleForm.project_type))]),
                      ]),
                      _vm._v(" "),
                      _c("tr", { staticClass: "concentbox" }, [
                        _c("th", [_vm._v("简章简介:")]),
                        _vm._v(" "),
                        _c("td", { attrs: { colspan: "3" } }, [
                          _c("div", {
                            staticClass: "isimg",
                            domProps: {
                              innerHTML: _vm._s(_vm.ruleForm.content),
                            },
                          }),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("tr", [
                        _c("th", [_vm._v("学费:")]),
                        _vm._v(" "),
                        _c("td", [
                          _c("span", [_vm._v(_vm._s(_vm.ruleForm.tuition))]),
                        ]),
                        _vm._v(" "),
                        _c("th", [_vm._v("报名费:")]),
                        _vm._v(" "),
                        _c("td", [
                          _c("span", [_vm._v(_vm._s(_vm.ruleForm.entry_fee))]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("tr", [
                        _c("th", [_vm._v("资料费:")]),
                        _vm._v(" "),
                        _c("td", [
                          _c("span", [
                            _vm._v(_vm._s(_vm.ruleForm.information_fee)),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("th", [_vm._v("材料费:")]),
                        _vm._v(" "),
                        _c("td", [
                          _c("span", [
                            _vm._v(_vm._s(_vm.ruleForm.materials_fee)),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("tr", [
                        _c("th", [_vm._v("申请费:")]),
                        _vm._v(" "),
                        _c("td", [
                          _c("span", [_vm._v(_vm._s(_vm.ruleForm.filing_fee))]),
                        ]),
                        _vm._v(" "),
                        _c("th", [_vm._v("总价:")]),
                        _vm._v(" "),
                        _c("td", [
                          _c("span", [_vm._v(_vm._s(_vm.ruleForm.tuition))]),
                        ]),
                      ]),
                    ]
                  ),
                ]),
              ]
            ),
          ],
          1
        )
      : _c(
          "div",
          {
            staticClass: "boxImg",
            staticStyle: { width: "100%", padding: "20px" },
          },
          [_c("system", { attrs: { title: _vm.title, tab: _vm.tab } })],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }